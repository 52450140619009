@import "src/scss/mixin";
@import "src/scss/vars";

.trust-wallet-banner {
  padding-bottom: 36px;

  &__grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    position: relative;
    z-index: 2;
    margin-top: 112px;
    max-width: 583px;
  }

  &__title {
    color: $c-blue--dark;
    font-weight: 700;
    @include fs-lh(36px, 48px);
    letter-spacing: 0.02em;
  }


  &__desc {
    margin-top: 16px;
    color: $c-link;
    @include fs-lh(24px, 40px);
    letter-spacing: 0.02em;
  }


  &__actions {
    margin-top: 24px;
  }

  &__btn {
    width: 225px;
    min-height: 46px;
    font-weight: 700;
  }

  &__note {
    margin-top: 24px;
    max-width: 360px;
    @include fs-lh(12px, 16px);
    color: $c-black;

    a {
      color: $c-ua-hg-primary-125;
      @include trCubic();

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  &__imgs {
    position: relative;
    margin-top: 32px;
    margin-right: 72px;
  }

  &__imgs-bg {
    position: absolute;
    z-index: 1;
    top: -16%;
    left: -34%;
    width: 168%;
    height: auto;
    transform-origin: center center;
  }

  &__img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    max-width: 405px;
    min-width: 375px;
  }
}


@media screen and (max-width: 1024px) {
  .trust-wallet-banner {
    padding-bottom: 32px;

    &__content {
      margin-top: 84px;
      max-width: 400px;
    }

    &__title {
      @include fs-lh(24px, 40px);
    }

    &__desc {
      @include fs-lh(16px, 28px);
    }

    &__actions {
      margin-top: 16px;
    }

    &__note {
      margin-top: 16px;
    }

    &__imgs {
      margin-top: 0;
      margin-right: 64px;
    }

    &__imgs-bg {
      position: absolute;
      z-index: 1;
      top: -16%;
      left: -34%;
      width: 168%;
      height: auto;
      transform-origin: center center;
    }

    &__img {
      max-width: 325px;
      min-width: auto;
    }
  }

}

@media screen and (max-width: 768px) {
  .trust-wallet-banner {
    &__grid {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    &__content {
      margin-top: 12px;
      max-width: 400px;
    }
    &__imgs {
      margin-top: 36px;
      margin-right: 0;
    }
    &__imgs-bg {
      top: -8%;
      left: -24%;
      width: 148%;
    }
    &__actions {
      margin-top: 36px;
    }
  }
}
