@import "src/scss/vars";
@import "src/scss/mixin";

.news {
  position: relative;
  padding-top: 200px;
  padding-bottom: 96px;
  &__title {
    color: $c-ua-hg-primary-150;
    font-size: 64px;
    font-weight: 700;
    line-height: 125%; /* 125% */
    letter-spacing: 1.92px;
  }
  &__grid {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    row-gap: 32px;
    margin-top: 36px;

  }

  &__card {
    padding: 8px;
    width: 25%;
    border-radius: 8px;
    text-decoration: none;
    &:hover,&:active {
      background-color: #E7F5FF;
    }
  }
  &__card-img {
    img {
      display: block;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
      aspect-ratio: 1.777;
      object-position: top center;
    }
  }
  &__card-title {
    margin-top: 8px;
    color: $c-bwl-900-d-100;
    font-size: 18px;
    font-weight: 700;
    line-height: 155.556%;
    letter-spacing: 0.18px;
  }
  &__card-text {
    margin-top: 8px;
    color: $c-bwl-600-d-400;
    font-size: 12px;
    font-weight: 400;
    line-height: 133.333%;
    @include text-dots(5);
  }
  &__actions {
    margin-top: 36px;
    text-align: center;

  }
  &__btn {
    width: 220px;
    path {
      stroke: currentColor;
    }
  }
}

@media screen and (max-width: 1024px) {
  .news {
    padding-top: 152px;
    padding-bottom: 60px;

    &__title {
      font-size: 36px;
    }
    &__card {
      width: 33.33%;
    }
  }
}

@media screen and (max-width: 768px) {
  .news {
    &__card {
      width: 50%;
    }
  }
}
@media screen and (max-width: 480px) {
  .news {
    &__card {
      width: 100%;
    }
  }
}
