@import "src/scss/mixin";
@import "src/scss/vars";

.weld-cta {
  padding-top: 114px;
  padding-bottom: 113px;
  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
  }
  &__content {
    width: 100%;
    max-width: 425px;
    margin-top: 80px;
  }
  &__img {
    position: relative;
    max-width: 665px;
    margin-right: 2.7%;
    margin-bottom: -160px;
    img {
      display: block;
      max-width: 100%;
      object-fit: cover;
    }
  }
  &__actions {
    margin-top: 40px;
  }
  &__btn {
    width: 340px;
  }
}

@media screen and (max-width: 1024px) {
  .weld-cta {
    padding-top: 64px;
    &__content {
      max-width: 345px;
    }
  }
}
@media screen and (max-width: 768px) {
  .weld-cta {
    padding-bottom: 66px;
    &__grid {
      flex-direction: column;
      gap: 2px;
    }
    &__content {
      max-width: 485px;
      margin-top: 0;
      text-align: center;
    }
    &__img {
      margin-right: 0;
      margin-bottom: 32px;
      img {
        max-width: 343px;
      }
    }
    &__actions {
      margin-top: 0px;
    }
  }
}
