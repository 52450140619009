@import "src/scss/vars";
@import "src/scss/mixin";

.ambassador {
  padding-top: 107px;
  padding-bottom: 104px;

  &__head {
    padding-top: 60px;
    padding-bottom: 90px;
    background-color: $c-link;
  }

  &__head-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  &__head-content {
    max-width: 605px;
  }

  &__head-title {
    color: $c-bwl-100-d-900;
    font-size: 64px;
    font-weight: 700;
    line-height: 125%; /* 125% */
    letter-spacing: 1.92px;
  }

  &__head-text {
    color: $c-bwl-100-d-900;
    margin-top: 32px;
    font-size: 24px;
    font-weight: 400;
    line-height: 166.667%; /* 166.667% */
    letter-spacing: 0.48px;
  }

  &__head-img {
    flex-grow: 1;
    width: 100%;
    max-width: 536px;

    img {
      width: 100%;
      height: auto;
      display: block;
      aspect-ratio: 1.072;
      object-fit: cover;
      object-position: center center;
    }
  }

  &__body {
  }

  &__body-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  &__form {
    width: 100%;
    max-width: 636px;
    margin-top: -80px;
    padding: 32px 40px;
    border-radius: 8px;
    background: $c-bwl-100-d-900;
    box-shadow: 0px 1.127px 2.755px 0px rgba(0, 0, 0, 0.04), 0px 2px 8px 0px rgba(0, 0, 0, 0.08), 0px 11px 27px 0px rgba(0, 0, 0, 0.09);
  }

  &__form-title {
    color: $c-bwl-700-d-300;
    font-size: 48px;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.96px;
  }
  &__form-field {
    & + & {
      margin-top: 12px;
    }
  }

  &__actions {
    margin-top: 32px;
  }
  &__btn {
    min-width: 125px;
  }

  &__message-text {
    margin-top: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 175%;
    color: $c-bwl-700-d-300;
  }
  &__body-content {
    max-width: 465px;
    ul {
      margin-bottom: 1em;
      list-style: disc;
      padding-inline-start: 26px;
      li {
        position: relative;
      }
    }
  }
  &__body-title {
    color: $c-bwl-700-d-300;
    font-size: 36px;
    font-weight: 400;
    line-height: 133.333%; /* 133.333% */
    letter-spacing: 0.72px;
  }
  &__body-text {
    margin-top: 40px;
    color: $c-bwl-700-d-300;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%; /* 175% */
  }
  &__body-email {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-top: 40px;
    padding: 8px 16px;
    color: $c-bwl-700-d-300;
    font-size: 16px;
    font-weight: 700;
    line-height: 175%; /* 175% */
    border-radius: 8px;
    border: 1px solid $c-bwl-300-d-700;
    background: $c-bwl-100-d-900;
    @include tr-cb();
    text-decoration: none;
    &:hover, &:active {
      border: 1px solid $c-link;

    }
  }
}

@media screen and (max-width: 1280px){
  .ambassador{
    &__form-title {
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .ambassador {
    padding-top: 75px;
    padding-bottom: 60px;

    &__head {
      padding-top: 36px;
      padding-bottom: 48px;
    }

    &__head-grid {
      gap: 24px;
    }

    &__head-content {
      max-width: 520px;
    }

    &__head-title {
      font-size: 36px;
    }

    &__head-text {
      margin-top: 24px;
      font-size: 16px;
    }

    &__head-img {
      max-width: 480px;
      min-width: 320px;
    }

    &__body-grid {
      gap: 24px;
    }


    &__form {
      min-width: 380px;
      margin-top: -40px;
      padding: 16px 24px;
    }

    &__form-title {
      font-size: 24px;
    }
    &__actions {
      margin-top: 24px;
    }

    &__message-text {
      margin-top: 24px;
    }
    &__body-content {
      max-width: 465px;
    }
    &__body-title {
      font-size: 24px;
    }
    &__body-text {
      margin-top: 24px;
    }
    &__body-email {
      gap: 8px;
      margin-top: 24px;
      padding: 8px 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .ambassador {


    &__head-grid {
      flex-direction: column-reverse;
      gap: 24px;
    }

    &__head-content {
      max-width: 480px;
    }

    &__head-title {
      font-size: 36px;
    }


    &__head-img {
      max-width: 320px;
      min-width: inherit;
    }

    &__body-grid {
      flex-direction: column-reverse;
    }


    &__form {
      max-width: 480px;
      min-width: inherit;
      margin-top: 24px;
    }


    &__body-content {
      max-width: 480px;
      margin-top: 36px;
    }
  }
}
