@import "../../../scss/mixin";
@import "../../../scss/vars";

.usage-slides {
  position: relative;
  z-index: 1;
  padding-top: 112px;

  &__title {
    margin-bottom: 92px;
    @include fs-lh(36px, 48px);
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: center;
  }

  &__scroll {
    position: relative;
  }

  &__grid {
    position: sticky;
    top: 146px;
    height: calc(100vh - 146px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 42px;
    width: 100%;
  }

  &__images {
    flex-shrink: 0;
    position: sticky;
    top: 0;
    height: 100%;
    width: 618px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
    aspect-ratio: 0.89;
    max-height: 100%;
    opacity: 0;
    @include tr-cb();

    &--active {
      opacity: 1;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__infos {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -40px;
    width: 100%;
    max-width: 415px;
    height: 100%;
    @include tr-cb();
  }

  &__info {
    & + & {
      margin-top: 16px;
    }

    &--active {
      .usage-slides__info-head {
        opacity: 1;
        color: $c-ua-hg-primary-125;
      }

      .usage-slides__info-body {
        grid-template-rows: 1fr;
      }
    }
  }

  &__info-head {
    margin-bottom: 16px;
    opacity: .25;
    @include fs-lh(36px, 48px);
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $c-ua-hg-primary-150;
    @include tr-cb();
  }

  &__info-body {
    display: grid;
    grid-template-rows: 0fr;
    @include tr-cb();
  }

  &__info-content {
    overflow: hidden;
  }

  &__info-text {
    margin-bottom: 16px;
    @include fs-lh(16px, 28px);
    font-weight: 400;
    color: $c-bwl-900-d-100;

    ul {
      margin-top: 16px;
      padding-inline-start: 2em;
      list-style: disc;
    }

    ol {
      margin-top: 16px;
      padding-inline-start: 1.2em;
      list-style: decimal;
    }
  }

  &__info-actions {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  &__info-btn {
    margin: 4px 0;
  }

  &__info-note {

    font-weight: 400;
    @include fs-lh(8px, 12px);
    color: $c-bwl-500-d-500;
  }

  &__info-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 6px;
    max-width: 340px;
    width: 100%;
    margin-bottom: 16px;
    padding: 8px 24px;
    font-weight: 400;
    background-color: rgba($c-bwl-100-d-900, .77);
    box-shadow: 0 1px 3px 0 #0000001C, 0 2px 2px 0 #0000000A, 0 0 2px 0 #0000000F;
    border-left: 3px solid $c-ua-hg-primary-125;
  }

  &__info-card-img {
    flex-shrink: 0;
    display: block;
    width: 24px;
    height: auto;
    object-fit: cover;
  }

  &__info-card-title {
    flex-grow: 1;
    white-space: nowrap;
    @include fs-lh(16px, 28px);
    color: $c-bwl-800-d-200;
  }

  &__info-card-desc {
    flex-shrink: 0;
    width: 160px;
    margin-left: 36px;
    @include fs-lh(12px, 16px);
    color: $c-bwl-900-d-100;
  }


}

@media screen and (max-width: 1140px) {
  .usage-slides {
    &__grid {
      gap: 24px;
    }

    &__images {
      width: 480px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .usage-slides {
    padding-top: 42px;

    &__title {
      margin-bottom: 48px;
      @include fs-lh(24px, 40px);
    }

    &__grid {
      top: 114px;
      height: calc(100vh - 114px);
    }

    &__images {

      width: 375px;
    }

    &__image {
      top: 10%;
    }

    &__info-head {
      @include fs-lh(24px, 40px);
    }
  }
}

@media screen and (max-width: 768px) {
  .usage-slides {
    &__title {
      margin-bottom: 24px;
    }

    &__item + &__item {
      margin-top: 40px;
    }

    &__info-head {
      text-align: center;
      opacity: 1;
      color: $c-ua-hg-primary-125;
    }

    &__image {
      position: relative;
      top: auto;
      left: auto;
      transform: translateX(0);
      opacity: 1;
      max-width: 350px;
      margin: 0 auto;
    }

    &__info {
      max-width: 485px;
      margin: 16px auto 0;
    }

    &__info-body {
      grid-template-rows: 1fr;
    }

    &__info-content {
      overflow: auto;
    }

    &__info-card {
      max-width: 100%;
      padding: 8px 8px 8px 24px;
    }

    &__info-card-desc {
      max-width: 300px;
      width: auto;
      flex-shrink: inherit;
    }

    &__info-actions {
      text-align: center;
    }

    &__info-btn {
      @include margin-center();
    }
  }
}

@media screen and (max-width: 425px) {
  .usage-slides {
    &__info-card-desc {
      max-width: 185px;
      width: 100%;
    }
  }
}
