@import "src/scss/mixin";
@import "src/scss/vars";

.home-reliability {
  position: relative;
  padding-top: 123px;
  &__bg-1 {
    position: absolute;
    z-index: 1;
    top: 117px;
    width: 367px;
    height: 463px;
    background: url('../../../assets/images/home-reliability-bg-1.png') center center no-repeat;
    background-size: cover;
  }

  &__bg-2 {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -288px;
    width: 301px;
    height: 560px;
    background: url('../../../assets/images/home-reliability-bg-2.png') center center no-repeat;
    background-size: cover;
  }

  &__title {
    font-weight: 700;
    @include fs-lh(36px, 48px);
    letter-spacing: 0.02em;
    text-align: center;
  }

  &__grid {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    margin-top: 16px;
  }

  &__img {
    flex-shrink: 1;
    width: 100%;
    margin-left: -100px;
    margin-right: -100px;
  }

  &__card {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: 48px;
    max-width: 389px;
    flex-shrink: 1;
    text-align: center;

  }

  &__card-img {
    display: block;
    margin: 0 auto;
    max-width: 277px;
    width: 100%;
    object-fit: cover;
  }

  &__card-text {
    margin-top: 32px;
    font-weight: 400;
    @include fs-lh(16px, 28px);
    text-align: center;

    b, strong {
      font-weight: 700;
    }

    a {
      color: $c-ua-hg-primary-125;
      text-decoration: underline;
      @include tr-cb();

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  &__card-text + &__card-text {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1024px) {
  .home-reliability {
    &__title {
      font-size: 24px;
    }

    &__img {
      max-width: 375px;
      margin-left: -60px;
      margin-right: -60px;
    }

    &__card-text {
      margin-top: 28px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-reliability {
    padding-bottom: 130px;

    &__bg-1 {
      left: -90px;
    }

    &__bg-2 {
      bottom: -220px;
    }

    &__grid {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__card {
      margin-top: 60px;
    }

    &__img {
      max-width: 420px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -90px;
    }
  }
}

@media screen and (max-width: 425px) {
  .home-reliability__card--left{
    .home-reliability__card-img {
      margin-right: 0;
      margin-left: auto;
    }
  }
}
