@import "src/scss/mixin";
@import "src/scss/vars";

.expander {
  @include trCubicFast();
  border-bottom: 1px solid $c-bwl-500-d-500;
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    color: $c-bwl-700-d-300;
    cursor: pointer;
  }
  &__title {
    padding: 18px 0;
    @include fs-lh(18px, 28px);
    font-weight: 700;
    letter-spacing: 0.01em;
    color: $c-blue--dark;
    text-align: left;
    @include trCubicFast();
  }
  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
  }
  &__body {
    margin-top: -8px;
    padding-bottom: 16px;
    @include fs-lh(16px, 28px);
    font-weight: 400;
    color: $c-bwl-700-d-300;
  }
}


