@import "src/scss/mixin";
@import "src/scss/vars";

.lang-toggler {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  &__btn{
    &--active{
      color: $c-bwl-100-d-900!important;
      background: $c-ua-hg-primary-150!important;
      border: 1px solid $c-ua-hg-primary-150!important;
    }
  }
  &__menu-list {
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    width: 170px;
    height: auto;
    background: $c-bwl-100-d-900;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    @include trCubicFast();
    transform: scale(.5);
    transform-origin: top right;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.09)) drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.06)) drop-shadow(0px 0.500862px 1.50259px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0.181152px 0.543456px rgba(0, 0, 0, 0.04));
    overflow: hidden;
    &--active {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
    &--current {
      pointer-events: none;
      color: $c-ua-hg-primary-100;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      border-radius: 4px;

      button, a {
        display: inline-flex;
        align-items: center;
        height: 56px;
        width: 100%;
        padding: 0 16px;
        text-align: left;
        font-size: 16px;
        line-height: 175%;
        cursor: pointer;
        background-color: transparent;
        border: none;

        &.active {
          color: var(--color-btn-hover);
        }

        &:hover {
          color: $c-bwl-100-d-900;
          background-color: $c-ua-hg-primary-100;
        }
      }
    }
  }
}
