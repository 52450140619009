@import "../../../scss/mixin";
@import "../../../scss/vars";

.about-token {
  overflow: hidden;

  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: $c-blue--dark;
  }

  &__box {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 635px;
    padding: 40px 56px 56px;
    background-color: rgba($c-bwl-100-d-900, 0.77);
    box-shadow: 0px 1px 3px 0px #0000001C, 0px 2px 2px 0px #0000000A, 0px 0px 2px 0px #0000000F;
    border-radius: 32px;
  }

  &__box-title {
    font-weight: 700;
    @include fs-lh(36px, 48px);
    letter-spacing: 0.02em;
  }

  &__box-text {
    margin-top: 24px;
    font-weight: 400;
    color: $c-bwl-700-d-300;
    @include fs-lh(16px, 28px);
  }

  &__box-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__box-link {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    margin-right: 20px;
    font-weight: 400;
    @include fs-lh(16px, 28px);
    color: $c-link;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:active {
      text-decoration: underline;
    }
  }

  &__globe {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__bg {
    position: absolute;
    z-index: 1;
    top: -60px;
    right: -330px;
  }

  &__card {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 380px;
    margin-right: -40px;
    padding: 22px 44px 44px;
    text-align: center;
    border-radius: 44px;
    border: 3px solid rgba($c-bwl-100-d-900, 0.6);
    box-shadow: 24px 23px 61px 0px #A7B7C726;
    background: linear-gradient(168.53deg, rgba(255, 255, 255, 0.3) 0%, rgba(247, 247, 247, 0.3) 97.46%);
    backdrop-filter: blur(2px);
  }

  &__card-title {
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $c-ua-hg-primary-125;
    @include fs-lh(98px, 112px)
  }

  &__card-text {
    margin-top: 12px;
    font-weight: 400;
    @include fs-lh(34px, 45px)
  }
}

@media screen and (max-width: 1360px) {
  .about-token {
    &__globe {
      flex-shrink: 0;
      min-width: 400px;
    }
    &__card {
      margin-right: -24px;
    }
  }
}

@media screen and (max-width: 1024px){
  .about-token {
    margin-bottom: -172px;
    &__grid {
      flex-direction: column;
      align-items: center;
    }

    &__box {
      padding: 24px 16px;
    }

    &__box-title {
      @include fs-lh(24px, 48px);
    }

    &__box-text {
      margin-top: 16px;
    }

    &__box-link {
      margin-top: 16px;
      margin-right: 16px;
    }

    &__bg {
      position: relative;
      z-index: 1;
      top: -65px;
      right: auto;
    }

    &__card {
      position: absolute;
      top: 133px;
      max-width: 190px;
      margin-right: auto;
      padding: 17px 22px 22px;
      border-radius: 22px;
      border: 1px solid rgba($c-bwl-100-d-900, 0.6);
    }

    &__card-title {
      @include fs-lh(48px, 58px)
    }

    &__card-text {
      margin-top: 6px;
      font-weight: 400;
      @include fs-lh(16px, 28px)
    }
  }
}

@media screen and (max-width: 640px){
  .about-token {
    margin-bottom: 0;
    &__globe {
      margin-top: 32px;
    }
    &__bg {
      top: auto;
    }
    &__card {
      top: 44px;
    }
  }
}
