@font-face {
  font-family: 'Vinnytsia Sans';
  src: url('../assets/fonts/VinnytsiaSans-Bold.eot');
  src: local('Vinnytsia Sans Bold'), local('VinnytsiaSans-Bold'),
  url('../assets/fonts/VinnytsiaSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/VinnytsiaSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/VinnytsiaSans-Bold.woff') format('woff'),
  url('../assets/fonts/VinnytsiaSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vinnytsia Sans';
  src: url('../assets/fonts/VinnytsiaSans-Regular.eot');
  src: local('Vinnytsia Sans Regular'), local('VinnytsiaSans-Regular'),
  url('../assets/fonts/VinnytsiaSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/VinnytsiaSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/VinnytsiaSans-Regular.woff') format('woff'),
  url('../assets/fonts/VinnytsiaSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
