@import "src/scss/mixin";
@import "src/scss/vars";

.home-faqs {
  position: relative;
  z-index: 1;
  padding: 112px 0;
  color: $c-bwl-700-d-300;

  &__grid {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1092px;
    margin: 0 auto;
  }

  &__aside {
    max-width: 340px;
    width: 100%;
    margin-right: 100px;
  }

  &__title {
    position: relative;
    max-width: 280px;
    padding-bottom: 20px;
    margin-bottom: 24px;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: 0.02em;
    color: $c-blue--dark;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 184px;
      border-bottom: 4px solid $c-ua-hg-primary-125;
    }
  }

  &__text {
    margin-bottom: 16px;
    @include fs-lh(16px, 28px);
  }

  &__link {
    display: inline-flex;
    align-items: center;

    text-decoration: none;
    @include trCubic();
    color: $c-ua-hg-primary-125;

    svg {
      margin-left: 8px;
    }

    &:hover, &:active {
      text-decoration: underline;
    }
  }

  &__content {
    flex-grow: 1;
    width: 100%;
    max-width: 653px;
  }

  &__expander {
  }
}

@media screen and (max-width: 1024px) {
  .home-faqs {
    padding: 48px 0;

    &__title {
      padding-bottom: 20px;
    }

    &__aside {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-faqs {
    &__grid {
      flex-direction: column;
    }

    &__aside {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
}
