@import "src/scss/mixin";
@import "src/scss/vars";

.weld-flow {
  padding-top: 112px;

  .weld__title {
    max-width: 745px;
    margin: 0 auto;
    text-align: center;
  }

  &__grid {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    margin: 90px auto 0;
    max-width: 920px;
  }

  &__info {
    position: relative;
    color: $c-ua-hg-primary-125;

    img {
      position: relative;
      z-index: 2;
      display: block;
      max-width: 537px;
      width: 100%;
    }
  }

  &__info-profit {
    position: absolute;
    top: 60px;
    left: -50%;
    z-index: 0;
    &__num {
      padding-top: 60px;
      line-height: 0.6 !important;
      font-weight: 700;
      @include fs-lh(290px, 453px);
    }

    &__text {
      line-height: 1 !important;;
      font-weight: 700;
      @include fs-lh(103px, 106px);
    }
  }

  &__list {
    margin-top: 43px;
  }

  &__card {
    position: relative;

    & + & {
      margin-top: 54px;
    }
  }

  &__card-head {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    span {
      font-weight: 400;
      @include fs-lh(16px, 28px);
      text-align: left;
      color: $c-ua-hg-primary-125;
    }
  }

  &__card-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 6.75px 9px 19.5px 0px #055FB926;
    overflow: hidden;

    img {
      display: block;
      width: 16px;
    }
  }

  &__card-text {
    position: relative;
    z-index: 1;
    max-width: 220px;
    font-weight: 700;
    @include fs-lh(16px, 28px);
    text-align: left;
    color: $c-turquoise-125;
  }

  &__card-num {
    position: absolute;
    top: -26px;
    left: calc(100% + 8px);
    @include fs-lh(96px, 124px);
    font-weight: 400;
    letter-spacing: 0.02em;
    text-align: left;
    color: $c-bwl-300-d-700--45;
  }

  &__card-arrow {
    position: absolute;
    top: calc(100% + 9px);
    left: 23px;
    width: 65px;
    height: auto;
  }

  &__card-btn {
    width: 220px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 1680px) {
  .weld-flow__grid {
    position: relative;
    left: 10%;
  }
}

@media screen and (max-width: 1280px) {
  .weld-flow {

    &__info {
      img {
        max-width: 375px;
      }
    }

    &__info-num {
    }

    &__info-text {
      top: 260px;
      left: -134px;
      font-size: 72px;
    }

    &__list {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .weld-flow {
    .weld__title {
      max-width: 485px;
    }

    &__grid {
      flex-direction: column;
      align-items: center;
      left: auto;
      margin: 36px auto 0;
    }

    &__info {
      margin-left: 22%;
    }

    &__card {
      position: relative;

      & + & {
        margin-top: 48px;
      }
    }

    &__card-img {
      width: 48px;
    }

    &__card-num {
      top: -27px;
    }

    &__card-arrow {
      top: calc(100% + 7px);
      width: 31px;
    }


  }
}

@media screen and (max-width: 768px) {
  .weld-flow {

    &__info {
      margin-left: 12%;

      img {
        max-width: 275px;
      }
    }

    &__info-profit {
      left: -35%;
      padding-left: 10px;

      &__num {
        padding-top: 30px;
        @include fs-lh(134px, 208px);
      }
      &__text {
        @include fs-lh(49px, 50px);
      }
    }

    &__card-btn {
      width: 240px;
      margin-top: 36px;
    }
  }
}

@media screen and (max-width: 600px) {
  .weld-flow {
    .weld__title {
      max-width: 345px;
    }
  }
}

@media screen and (max-width: 480px) {
  .weld-flow {

    &__info {
      margin-left: 22%;

    }
  }
}
