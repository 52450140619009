//---COLORS
//---old color scheme, approve or remove color
$c-bwl-300-d-700: #e0e1e3;
$c-bwl-600-d-400: #6D6D6D;
$c-orange: #FF7B56;
$c-bwl-300-d-700: #E0E1E3;
$c-bwl-300-d-700--45: rgba($c-bwl-300-d-700,0.45);
$c-bwl-400-d-600: #CACBCE;
$c-bwl-500-d-500: #939393;
$c-turquoise: #638E9C;


//---new color scheme
$c-black: #000000;
$c-blue--dark: #03131F;
$c-link: #376FE5;
$c-bg: #F7F7F7;
$c-bg-shadow: 0 1px 10px 0px rgba(0, 0, 0, 0.11);
$c-box: #E0EFFF;
$c-field--focus: #FAD400;
$c-turquoise-125: #3B6895;

$c-uah-blue: #338df5;

$c-uah-blue-light: #cee6ff;
$c-ua-hg-primary-75:#48A0F9;
$c-ua-hg-primary-100: #0790F1;
$c-ua-hg-primary-125: #0071F2;
$c-ua-hg-primary-150: #054997;

$c-uah-green-100: #13D659;

$c-bwl-100-d-900: #ffffff;
$c-bwl-300-d-700--75: #F3F4F6;
$c-bwl-700-d-300: #403F3F;
$c-bwl-800-d-200: #302F2F;
$c-bwl-900-d-100: #2A2927;

$c-error: #FC3A25;
$c-info: #2196f3;
$c-success: #3bdc42;
$c-warning: #ffc107;



//---ANIMATIONS
@keyframes gradient-animation {
  0% {
    background-position: 0% 504px;
  }
  100% {
    background-position: 0% 0px;
  }
}


//---TRANSITION
$trans-time: .4s;
$trans-time--fast: .3s;
$trans-time--slow: .6s;

//---FONTS-FAMILY
$f-family-museo: 'MuseoModerno', sans-serif;
$f-family-kyiv: 'KyivType Sans', sans-serif;

