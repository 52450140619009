@import "../../../scss/mixin";
@import "../../../scss/vars";

.net-contracts {
  padding-top: 112px;

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  &__title {
    max-width: 311px;
    font-weight: 700;
    @include fs-lh(36px, 48px);
    letter-spacing: 0.02em;
    color: $c-blue--dark;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 8px 24px;
    background-color: rgba($c-bwl-100-d-900,.77);
    border-left: 3px solid $c-ua-hg-primary-125;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.11), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06);

    & + & {
      margin-top: 16px;
    }
  }

  &__card-icon {
    flex-shrink: 0;
    width: 48px;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__card-name {
    flex-shrink: 0;
    width: 150px;
    margin-left: 16px;
    font-weight: 700;
    @include fs-lh(16px, 28px);

  }

  &__card-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    gap: 8px;
    margin-left: 24px;
    padding: 10px 12px;
    background-color: $c-bwl-100-d-900;
    border: 1px dashed $c-bwl-300-d-700;
    border-radius: 4px;
    @include fs-lh(16px, 28px);
    a {
      flex-grow: 1;
      @include trCubic();
      color: $c-link;
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }
    }

    svg {
      width: 24px;
      height: auto;
      color: $c-bwl-400-d-600;
      cursor: pointer;

      &:hover, &:active {
        color: $c-orange;
      }

      path {
        @include trCubic()
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .net-contracts {
    padding-top: 48px;
    &__grid {
      flex-direction: column;
      gap: 63px;
    }

    &__title {
      max-width: 485px;
      @include fs-lh(24px, 40px);
    }

    &__card-icon {
      width: 32px;
    }

    &__card-content {
      margin-left: 16px;
      padding: 8px 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .net-contracts {
    &__card {
      flex-wrap: wrap;
    }
    &__card-content {
      align-items: flex-start;
      width: 100%;
      margin-left: 0;
      margin-top: 8px;
      a {
        word-break: break-word;
      }
    }
  }
}

