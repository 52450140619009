@import "src/scss/mixin";
@import "src/scss/vars";

.trust-wallet-icons {
  padding-top: 112px;

  &__box {
    padding: 40px 56px 56px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6.2px 0px rgba(109, 99, 219, 0.0784313725);
    background-color: #ffffff;
    border-radius: 32px;
  }

  &__title {
    margin-bottom: 60px;
    text-align: center;
    font-weight: 700;
    color: $c-blue--dark;
    @include fs-lh(36px, 48px);
    letter-spacing: 0.02em;
  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 56px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 326px;
  }

  &__card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 64px;
    aspect-ratio: 1;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    box-shadow: inset -9px -12px 26px 0px rgba(5, 95, 185, 0.075), 9px 12px 26px 0px rgba(5, 95, 185, 0.15);

    img {
      display: block;
      max-width: 32px;
      height: auto;
      object-fit: cover;
    }

    svg {
      width: 32px;
      height: auto;
    }
  }

  &__card-title {
    flex-shrink: 0;
    margin-top: 16px;
    font-weight: 700;
    @include fs-lh(18px, 28px);
    letter-spacing: 0.01em;
  }

  &__card-text {
    flex-grow: 1;
    margin-top: 16px;

    font-weight: 400;
    @include fs-lh(16px, 28px);

    b, strong {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 1024px) {
  .trust-wallet-icons {
    padding-top: 64px;

    &__box {
      padding: 32px 16px;
      border-radius: 12px;
    }

    &__grid {
      flex-wrap: wrap;
      justify-content: space-around;

    }

    &__title {
      margin-bottom: 56px;
      @include fs-lh(24px, 40px);
    }

    &__card {
      width: calc(50% - 28px);
      max-width: 485px;

      &:nth-child(3) {
        width: 100%;
      }
    }

  }
}

@media screen and (max-width: 640px) {
  .trust-wallet-icons {
    text-align: center;

    &__grid {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      max-width: 485px;
      text-align: left;
    }

    &__card {
      width: 100%;
    }
  }
}
