@import "src/scss/vars";
@import "src/scss/mixin";

.home {
  position: relative;
  padding-top: 150px;
  background-color: $c-bg;

  &__oveflow {
    overflow: hidden;
  }

  &__about-token {
    padding-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .home {
    padding-top: 104px;
  }
}


@media screen and (max-width: 768px) {
  .home {
    &__about-token {
      padding-top: 112px;
    }
  }
}
