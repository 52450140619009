@import "src/scss/mixin";
@import "src/scss/vars";

.sidebar {
  &--active {
    .sidebar__overlay {
      opacity: .2;
      visibility: visible;
    }

    .sidebar__aside {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }

  &__overlay {
    position: fixed;
    z-index: 25;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $c-black;
    opacity: 0;
    visibility: hidden;
    @include trCubic();
  }

  &__aside {
    position: fixed;
    z-index: 30;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 312px;
    bottom: 0;
    padding: 16px 0 32px;
    opacity: 0;
    visibility: hidden;
    color: $c-bwl-700-d-300;
    background-color: $c-bwl-100-d-900;
    box-shadow: 5px 4px 12px rgba(0, 0, 0, 0.51);
    @include trCubic();
    transform: translateX(calc(100% + 24px));
  }

  &__head {
    position: relative;
    padding: 0 32px 32px;
  }

  &__logo {
    display: flex;
    justify-content: center;
  }

  &__close {
    position: absolute;
    top: 0px;
    right: 16px;
    width: 40px;
    height: 40px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__content {
    flex-grow: 1;
    padding: 0 32px;
  }
  &__actions {
    flex-shrink: 0;
    padding: 32px 32px;

    .sidebar__btn {
      width: 100%;
    }
  }
  &__footer {

  }
}
