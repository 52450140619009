@import "src/scss/mixin";
@import "src/scss/vars";

.loader {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $c-bwl-100-d-900;
  opacity: 0;
  visibility: hidden;
  @include trLinearSlow();
  &--active {
    opacity: 1;
    visibility: visible;
  }
}

.loader__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  will-change: transform;
  animation: pulse 1s linear infinite;
  img {
    width: 80px;
    @include trLinearSlow();
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
