@mixin trCubicFast($time: .15s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@mixin trCubic($time: .3s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@mixin trLinearSlow($time: .5s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: linear
}

@mixin trCubicSlow($time: .8s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
}

@mixin trEaseInOut($time: .3s) {
  transition-duration: $time;
  transition-property: all;
  transition-timing-function: ease-in-out
}



@mixin shadow-block--big() {
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 4%);
}

@mixin shadow-block--small() {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%);
}


//@mixin gradient--green() {
//  background-image: linear-gradient(310deg,$color-success,#3BDC42FF);
//}

@mixin margin-center() {
  margin-left: auto;
  margin-right: auto
}

@mixin list-reset-style() {
  list-style: none !important;
  padding-left: 0 !important;
  color: lightgray;
}

@mixin text-dots($line-clamp: 1) {
  @if $line-clamp <= 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@mixin fs-lh($fs: 14px, $lh: 24px) {
  font-size: $fs;
  line-height: calc($lh / $fs);
}

@mixin fs($fs: 14px, $dec: 0.8) {
  font-size: $fs;

  @if (calc($fs / (1px)) > 14) {
    @media (max-width: 1024px) {
      font-size: calc($fs / (1px) * $dec / (1px));
    }
    @media (max-width: 768px) {
      font-size: calc($fs/(1px) * $dec/(1px) * $dec/(1px));
    }
  }
}


@mixin fs-lh--adp($fs: 14px, $lh: 20px,$dec: 0.8) {
  font-size: $fs;
  line-height: calc($lh / $fs);

  @media (max-width: 1024px) {
    @if (calc(($fs * $dec) / (1px)) > 16) {
      font-size: $fs * $dec;
      line-height: calc(($lh * $dec) / ($fs * $dec));
    } @else {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  //@if (calc(($fs * $dec * $dec) / (1px)) > 14) {
  //  @media (max-width: 768px) {
  //    font-size: $fs * $dec * $dec;
  //    line-height: calc(($lh * $dec * $dec) / ($fs * $dec * $dec));
  //  }
  //}

  @media (max-width: 768px) {
    @if (calc(($fs * $dec * $dec) / (1px)) > 14) {
      font-size: $fs * $dec * $dec;
      line-height: calc(($lh * $dec * $dec) / ($fs * $dec * $dec));
    } @else {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

@mixin tr-cb($time: .4s) {
  transition: all $time cubic-bezier(.25, .8, .25, 1)
}

@mixin scrollbar($type: 'vertical',  $customSize: 6px ) {
  $size: $customSize;

  &::-webkit-scrollbar {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: calc($size/2);
    @if ($type == 'vertical') {
      width: $size;
      border-left: 2px solid $c-bwl-100-d-900;
      border-right: 2px solid $c-bwl-100-d-900;
      background: $c-bwl-800-d-200;
    } @else {
      height: $size;
    }

  }
  &::-webkit-scrollbar-thumb {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    background: $c-bwl-400-d-600;
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-thumb:hover {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    background: $c-bwl-400-d-600;
    border-radius: calc($size/2);
    cursor: pointer;
  }
}