@import "src/scss/mixin";
@import "src/scss/vars";

.news-slider {
  padding: 24px;
  border-radius: 16px;
  background: #EFF7FE;

  &__head {
  }

  &__title {
    margin-bottom: -38px;
    color: $c-ua-hg-primary-150;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 166.667%;
    letter-spacing: 0.48px;
  }

  &__content {
    position: relative;
    padding-right: 100px;
    overflow: hidden;
    &:after {
      content: '';
      z-index: 1;
      position: absolute;
      top: 46px;
      right: 0;
      bottom: 0;
      width: 15%;
      background: linear-gradient(90deg, transparent 0%, #EFF7FE 100%);
    }
    .swiper-container {
      width: 100%;
      height: 80%;
    }
    .swiper-slide {
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }

  &__card {
    display: block;
    text-decoration: none;
    color: $c-bwl-900-d-100;
    &:hover,&:active {
      text-decoration: underline;
      img {
        transform: scale(1.2);
      }
    }

  }

  &__card-img {
    overflow: hidden;
    border-radius: 8px;
    img {
      display: block;
      width: 100%;
      object-fit: cover;

      transform: scale(1);
      will-change: transform;
      @include tr-cb();
    }
  }
  &__card-title {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%;
  }

  .swiper {

    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    column-gap: 8px;
    overflow: visible;
  }

  .swiper-pagination {
    order: 2;
    position: relative;
    left: auto;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    bottom: auto;
    width: auto;
    height: 36px;
    margin-bottom: 16px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 8px!important;
    border-radius: 8px;
    background: $c-bwl-400-d-600;
    opacity: 1;
    &:hover,&:active{
      background: $c-ua-hg-primary-100;
    }
    &.swiper-pagination-bullet-active {
      background: $c-ua-hg-primary-100;
    }
  }

  .swiper-wrapper {
    order: 4;
  }

  .swiper-button-prev, .swiper-button-next {
    position: relative;
    left: auto;
    right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-top: 0;
    margin-bottom: 18px;
    color: $c-bwl-500-d-500;
    &:after{
      position: relative;
      font-size: 30px;
    }
    &:hover,&:active {
      color: $c-ua-hg-primary-100;

    }
  }

  .swiper-button-prev {
    order: 1;
  }

  .swiper-button-next {
    order: 3;
  }

  .swiper-pagination,
  .swiper-button-prev,
  .swiper-button-next {
    transform: translateX(100px);
  }

}


@media screen and (max-width: 1024px){
  .news-slider {
    .swiper {
      justify-content: center;
      align-items: center;
      column-gap: 40px;
    }

    .swiper-wrapper {
      order: 0;
    }

    .swiper-pagination {
      display: none;
    }
    .swiper-button-prev,
    .swiper-button-next {
      transform: translateX(50px);
      margin-bottom: 0;
    }

    .swiper-pagination {
      flex-wrap: wrap;
      height: auto;
      max-width: calc(100% - 100px);
    }
  }
  .news-slider__title{
    margin-bottom: 16px;
  }
  .news-slider__content {
    &:after {
      top: 0px;
      bottom: 46px;
    }
  }
}





