@import "src/scss/mixin";
@import "src/scss/vars";

.reserves-main {
  padding-bottom: 220px;
  &__grid {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bg {
    position: absolute;
    z-index: 0;
    top: 86%;
    left: 54%;
    margin-top: -562px;
    margin-left: -571px;
    width: 1143px;
    height: 1124px;
    svg {
      width: 100%;
      height: auto;
      opacity: .6;
      transform: rotate(-15deg);
    }
  }
  &__banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 36px;
    position: relative;
    z-index: 1;
    max-width: 618px;
    width: 100%;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    max-width: 400px;
  }
  &__title {
    max-width: 545px;
    color: $c-blue--dark;
    font-size: 48px;
    font-weight: 700;
    line-height: 125%; /* 125% */
    letter-spacing: 1.92px;
  }
  &__text {
    max-width: 545px;
    margin-top: 32px;
    color: $c-ua-hg-primary-125;
    font-size: 24px;
    font-weight: 400;
    line-height: 166.667%; /* 166.667% */
    letter-spacing: 0.48px;
  }
}

@media screen and (max-width: 1024px){
  .reserves-main {
    padding-bottom: 60px;
    &__bg {
      top: 86%;
      left: 54%;
      margin-top: -562px;
      margin-left: -571px;
      width: 1143px;
      height: 1124px;
    }
    &__banner{
      max-width: 352px;
    }

    &__title {
      font-size: 54px;
    }
  }

}

@media screen and (max-width: 1024px){
  .reserves-main {
    padding-bottom: 60px;
    &__grid {
      flex-direction: column;
      justify-content: center;
      gap: 32px;
    }
    &__banner{
      margin-right: 0;
      max-width: 343px;
    }

    &__title {
      font-size: 36px;
    }
  }
}
