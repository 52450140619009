@import "../../../scss/mixin";
@import "../../../scss/vars";

.price-pool {
  padding-top: 85px;
  text-align: center;

  &__title {
    color: $c-blue--dark;
    font-weight: 700;
    @include fs-lh(36px,48px);
    letter-spacing: 0.02em;
    span {
      white-space: nowrap;
    }
  }

  &__desc {
    max-width: 658px;
    @include margin-center();
    margin-top: 16px;
    @include fs-lh(16px,28px)
  }
  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    margin-top: 32px;
  }
  &__card {
    width: calc(50% - 8px);
    border-radius: 16px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  &__note {
    margin-top: 8px;
    color: $c-bwl-600-d-400;
    @include fs-lh(10px,12px)
  }
}

@media screen and (max-width: 1024px) {
  .price-pool {
    padding-top: 32px;

    &__title {
      @include fs-lh(24px,40px);
    }

    &__desc {
      margin-top: 24px;
    }
    &__grid {
      margin-top: 24px;
    }
    &__card {
      border-radius: 9px;
    }
    &__note {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .price-pool {
    &__grid {
      flex-direction: column;
    }
    &__card {
      width: 100%;
    }
  }
}

