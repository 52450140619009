@import "src/scss/vars";
@import "src/scss/mixin";

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.how-to-use-section {

  padding: 50px 0 24px 0;
  
  &-box {
    padding: 40px 58px 56px 58px;
    background: $c-bwl-100-d-900;
    border-radius: 32px;
    border: 2px solid $c-bwl-100-d-900;
  }

  &-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      padding-bottom: 32px;
    }

    &-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      &-icon-crypto {
        svg {
          background: $c-ua-hg-primary-125;
          border-radius: 12px;
        }
      }

      &-icon-uah {
        svg {
          background: $c-bwl-100-d-900;
          border-radius: 12px;
        }
      }

      button {
        height: 46px;
        transition: all 0.4s ease-out;
      }

      button:hover {
        color: white;
        background: $c-ua-hg-primary-125;
        border: 0;
        transition: all 0.4s ease-out;

      }
      button:nth-child(2):hover {
        svg {
          border-radius: 100px;
          background: $c-bwl-100-d-900;
        }
      }
      .activeTab:hover {
        color: $c-bwl-700-d-300;
        background: white;
        border: 1px solid $c-ua-hg-primary-125;
        transition: all 0.4s ease-out;
      }
      .activeTab:nth-child(1):hover {
        svg {
          border-radius: 100px;
          background: $c-ua-hg-primary-125;
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 56px;
    padding-top: 32px;

    &-card {
      width: 25%;
      max-width: calc(25% - (56px*3)/4);
      transition: all 0.4s ease-out;
      padding: 0 10px;
      &-wrap-img {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 64px;
        img {
          max-height: 64px;
        }
      }
      h5 {
        font-size: 18px;
        font-weight: 700;
        color: $c-bwl-900-d-100;
        padding: 16px 0;
      }
      p {
        font-size: 16px;
        color: $c-bwl-700-d-300;
      }
    }
    &-card:hover {
      scale: 1.06;
      transition: all 0.4s ease-out;
    }
  }
}

@media screen and (max-width: 720px) {
  .how-to-use-section {
    &-head {
      &-btns {
        width: 100%;
        flex-direction: column;
        gap: 16px;
        button {
          width: 100%;
        }
      }
    }
    &-list {
      flex-direction: column;
      &-card {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}