@import "src/scss/mixin";
@import "src/scss/vars";

.header {
  color: $c-bwl-700-d-300;
  backdrop-filter: blur(8px);
  background-color: rgba(255,255,255,0.2);
  &__top {
    align-items: center;
    background-color: $c-blue--dark;
    display: flex;
    justify-content: center;
    min-height: 32px;
    gap: 20px;
  }
  &__top-bar {
    a {
      color: $c-bwl-100-d-900;
      font-size: 18px;
      font-weight: 700;
      text-decoration: none;
      text-align: center;
      &:hover,&:active {
        text-decoration: underline;
      }
    }
  }
  &__top-icon {
    height: 32px;
  }
  &__progress {
    height: 8px;
  }
  &__progress-bar {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #FED1BE 0%,#6DB3F8 10% ,#6DB3F8 100%);
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1352px;
    margin: 0 auto;
    padding: 32px 0;
  }
  &__nav {
    margin: 0 16px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__btn{
    min-width: 115px;
    margin-left: 15px;
  }
  &__btn-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    &--active{
      color: $c-bwl-100-d-900!important;
      background: $c-ua-hg-primary-150!important;
      border: 1px solid $c-ua-hg-primary-150!important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header__content {
    padding: 16px 0;
  }
}

@media screen and (max-width: 475px) {
  .header {
    &__top {
      gap: 15px;
      &-bar a {
        font-size: 14px;
      }
    }
  }
}
