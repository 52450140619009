@import "src/scss/vars";
@import "src/scss/mixin";

.article {
  position: relative;
  padding-top: 200px;
  padding-bottom: 96px;
  &__wrapper {
    max-width: 640px;
    margin: 0 auto;
  }
  &__img {
    img {
      display: block;
      width: 100%;
      //aspect-ratio: 1.777;
      object-fit: cover;
      //object-position: top center;
      border-radius: 8px;
    }
  }

  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    margin-top: 40px;

  }

  &__title {
    color: $c-blue--dark;
    font-size: 40px;
    font-weight: 700;
    line-height: 133.333%;
    letter-spacing: 0.72px;
  }
  &__date {
    color: $c-bwl-500-d-500;
    font-size: 14px;
    font-weight: 400;
    line-height: 171.429%;
    letter-spacing: 0.7px;
    text-transform: uppercase;
  }
  &__content {
    margin-top: 24px;
    color: $c-bwl-800-d-200;
    font-size: 18px;
    font-weight: 400;
    line-height: 177.778%; /* 177.778% */
    letter-spacing: 0.18px;
    ul {
      margin-bottom: 1em;
      li {
        position: relative;
        padding-left: 24px;
        &:after {
          content: '';
          position: absolute;
          top: 12px;
          left: 0;
          width: 10px;
          height: 10px;
          background-color: #d9d9d9;
        }
      }
    }
  }
  &__related {
    margin-top: 64px;
  }
}

@media screen and (max-width: 1024px) {
  .team {
    padding-top: 152px;
    padding-bottom: 60px;

    &__title {
      font-size: 36px;
    }
    //&__card {
    //  width: calc(50% - 18px);
    //}
  }
}
