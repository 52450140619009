@import "src/scss/mixin";
@import "src/scss/vars";

.reserves-emission {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  color: $c-bwl-700-d-300;
  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
  }
  &__content {
    max-width: 620px;
    padding: 56px;
    padding-top: 40px;
    box-shadow: $c-bg-shadow;
    border-radius: 32px;
    background-color: $c-bwl-100-d-900;
    z-index: 1;
  }
  &__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.96px;
  }
  &__text {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%; /* 175% */
  }
  &__proofs{
    display: inline-block;
    margin-top: 48px;
    li + li {
      margin-top: 16px;
      border-top: 1px solid $c-bwl-300-d-700;
    }
  }
  &__proofs-title{
    margin-bottom: 32px;
    font-size: 36px;
    font-weight: 700;
    line-height: 133.333%; /* 133.333% */
    letter-spacing: 0.72px;
  }
  &__proofs-total {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    padding: 8px 0 16px;
    border-bottom: 3px solid $c-ua-hg-primary-100;

    img {
      display: block;
      width: 24px;
      aspect-ratio: 1;
      object-fit: cover;
    }
    span {
      color: $c-bwl-700-d-300;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px; /* 175% */
    }

  }
  &__proofs-list {

    li + li {
      margin-top: 8px;
    }
  }
  &__proofs-card {
    width: 100% ;
  }
  &__img {
    display: block;
    position: relative;
    left: 0;
    right: 0;
    width: 620px;
    height: 863px;
    img {
      display: block;
      position: absolute;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: 1280px){
  .reserves-emission {
    &__grid {

      gap: 36px;
    }}
}
@media screen and (max-width: 1024px) {
  .reserves-emission {
    &__grid {
      flex-direction: column;
    }
    &__title {
      font-size: 36px;
    }
    &__text {
      margin-top: 16px;
    }
    &__proofs{
      margin-top: 24px;
    }
    &__proofs-title{
      font-size: 32px;
    }
    &__proofs-list {
      margin-top: 16px;
    }
    &__img {
      height: 500px;
      img {
        left: -33%;
        transform: rotate(90deg);
      }
    }
  }
}
