@import "src/scss/mixin";
@import "src/scss/vars";

.proof-extended-card {
  border-radius: 6px;
  padding-top: 8px;
  &--dark {
    .proof-extended-card__label {
      color: $c-bwl-500-d-500;

    }

  }
  &__head {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    flex-grow: 1;

    img {
      display: block;
      width: 24px;
      aspect-ratio: 1;
      object-fit: cover;
    }

    span {
      color: $c-bwl-700-d-300;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px; /* 175% */
    }
  }

  &__body {
    margin-top: 8px;
  }
  &__label {
    margin-bottom: -4px;
    color: $c-bwl-300-d-700;
    font-size: 12px;
    font-weight: 400;
    line-height: 133.333%; /* 133.333% */
  }

  &__link {

      color: $c-ua-hg-primary-125;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px; /* 175% */
      text-decoration-line: underline;
      @include trCubic();
      &:hover, &:active {
        text-decoration: none;
      }

  }
  &__btn {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 768px) {
  .proof-extended-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 8px;
    &__link {
      width: 100%;
      min-width: auto;
      word-break: break-all;
    }
  }
}
