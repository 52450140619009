@import "src/scss/vars";
@import "src/scss/mixin";

.reserves {
  position: relative;
  padding-top: 200px;
}

@media screen and (max-width: 1024px) {
  .reserves {
    padding-top: 152px;
  }
}
