@import "src/scss/mixin";
@import "src/scss/vars";

.home-main {
  position: relative;
  padding-top: 50px;

  &__bg {
    position: absolute;
    left: 0;
    bottom: -425px;
  }

  &__grid {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__info {
    max-width: 500px;
    width: 100%;
  }

  &__title {
    @include fs-lh(36px, 48px);
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $c-blue--dark;
  }

  &__desc {
    margin-top: 16px;
    @include fs-lh(18px, 32px);
    font-weight: 400;
    letter-spacing: 0.05em;
    @include tr-cb();
    color: $c-bwl-700-d-300;
    a {
      color: $c-ua-hg-primary-125;
      text-decoration: underline;
      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  &__actions {
    margin-top: 48px;
  }

  &__btn {
    width: 310px;
    margin-left: -2px;
    font-weight: 700;
  }

  &__note {
    margin-top: 16px;
    @include fs-lh(16px, 28px);
    font-weight: 400;
    color: $c-ua-hg-primary-150;
  }

  &__banner {
    position: relative;
    max-width: 54%;
    img {
      display: block;
      max-width: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-main {

    &__info {
      max-width: 345px;
    }

    &__title {
      @include fs-lh(24px, 40px);
    }

    &__desc {
      margin-top: 16px;
    }

    &__actions {
      margin-top: 16px;
    }

    &__banner {
      position: relative;
      width: 485px;
    }
  }
}

@media screen and (max-width: 768px){
  .home-main {

    &__bg {
      position: absolute;
      left: 0;
      bottom: -425px;
    }

    &__grid {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      max-width: 485px;
    }

    &__title {
      @include fs-lh(24px, 40px);
    }

    &__actions {
      max-width: 485px;
      width: 100%;
      margin-top: 32px;
      text-align: center;
    }

    &__note {
      text-align: center;
    }

    &__banner {
      max-width: 485px;
      width: 100%;
      margin-top: 16px;
    }
  }
}

