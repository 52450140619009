@import "src/scss/mixin";
@import "src/scss/vars";

.reserves-funds {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  color: $c-bwl-700-d-300;
  background-color: #F0F0F0;
  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
  }
  &__content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 620px;
  }
  &__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.96px;
  }
  &__text {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%; /* 175% */
  }
  &__proofs{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 48px;
  }
  &__proofs-title{
    font-size: 36px;
    font-weight: 700;
    line-height: 133.333%; /* 133.333% */
    letter-spacing: 0.72px;
  }
  &__proofs-list {
    margin-top: 32px;
  }
  &__proofs-card {
    width: 100% ;
  }
  &__img {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1280px){
  .reserves-funds {
    &__grid {
      gap: 36px;
    }}
}
@media screen and (max-width: 1024px) {
  .reserves-funds {
    &__grid {
      flex-direction: column-reverse;
    }
    &__title {
      font-size: 36px;
    }
    &__text {
      margin-top: 16px;
    }
    &__proofs{
      margin-top: 24px;
    }
    &__proofs-title{
      font-size: 32px;
    }
    &__proofs-list {
      margin-top: 16px;
    }
    &__img {
      img {
        max-width: 343px;
      }
    }
  }
}
