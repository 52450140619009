@import "src/scss/mixin";
@import "src/scss/vars";

.reserves-ownership {
  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;
  color: $c-bwl-700-d-300;

  .app__wrapper {
    display: flex;
    justify-content: center;
  }

  &__img-rt {
    position: relative;
    top: 40%;
    width: 20%;
  }
  &__img-lf {
    position: relative;
    left: -60px;
    top: -10%;
    width: 40%;
  }

  &__grid {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 56px;
    box-shadow: $c-bg-shadow;
    border-radius: 32px;
    background-color: $c-bwl-100-d-900;
  }
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 620px;
  }
  &__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.96px;
    text-align: center;
  }
  &__text {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%; /* 175% */
    text-align: center;
  }
  &__proofs{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 48px;
  }
  &__proofs-title{
    font-size: 36px;
    font-weight: 700;
    line-height: 133.333%; /* 133.333% */
    letter-spacing: 0.72px;
  }
  &__img {
    img {
      display: block;
      object-fit: cover;
      max-width: 480px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 1280px){
  .reserves-ownership {
    padding-bottom: 60px;
    &__grid {
      gap: 36px;
    }}
}
@media screen and (max-width: 1024px) {
  .reserves-ownership {
    &__grid {
      flex-direction: column-reverse;
    }
    &__title {
      font-size: 36px;
    }
    &__text {
      margin-top: 16px;
    }
    &__proofs{
      margin-top: 24px;
    }
    &__proofs-title{
      font-size: 32px;
    }
    &__proofs-list {
      margin-top: 16px;
      width: 100%;
      .proof-card {
        width: 100%;
        &__content {
          width: 100%;
          justify-content: center;
        }
        a {
          width: 100%;
        }
      }
    }
    &__img {
      img {
        max-width: 343px;
      }
    }
    &__img-rt {
      top: 60%;
      left: 130px;
      width: 100px;
    }
    &__img-lf {
     width: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .reserves-ownership {
    &__img-rt {
      top: 70%;
      left: 45%;
      width: 100px;
    }
  }
}
