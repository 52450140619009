@import "src/scss/vars";
@import "src/scss/mixin";

.trust-wallet {
  position: relative;
  padding-top: 150px;
  background-color: $c-bg;
  &__oveflow {
    overflow: hidden;
  }
  &__about {
    padding-top: 112px;
  }
  &__market-services {
    padding-top: 24px;
  }
  &__net-contracts {
    padding-top: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .trust-wallet {
    padding-top: 104px;
    &__about {
      padding-top: 64px;
    }
  }
}

