@import "src/scss/mixin";
@import "src/scss/vars";

.app-modal {
  position: fixed;
  z-index: 21;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 0 16px;

  &--active {
    .app-modal__overlay {
      opacity: .2;
      visibility: visible;
    }

    .app-modal__wrapper {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $c-black;
    opacity: 0.2;
    visibility: hidden;
    @include trCubic(.3s);
  }

  &__wrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90%;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10%);
    background: $c-bwl-100-d-900;
    border-radius: 12px;
    box-shadow: 0px 11px 27px rgba(0, 0, 0, 0.09), 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 1.12694px 2.75474px rgba(0, 0, 0, 0.04);
    @include trCubic(.3s);
  }
  &__close {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: $c-bwl-700-d-300;
    &:hover,&:active {
      color: $c-orange
    }
  }
  &__content-scroll {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include scrollbar()
  }
}

@media screen and (max-width: 768px) {
  .app-modal {
    align-items: flex-end;
    padding: 0 8px;
    &--active {
      .app-modal__wrapper {
        transform: translateY(0);
      }
    }
    &__wrapper {
      max-height: 95%;
      transform: translateY(100%);
      border-radius: 12px 12px 0px 0px;
    }
  }
}
