@import "src/scss/mixin";
@import "src/scss/vars";

.app-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 3px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  @include trEaseInOut();
  text-decoration: none;
  text-transform: none;
  text-rendering: auto;

  &__content {
    position: relative;
    z-index: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Vinnytsia Sans';
  }

  &__loader {
    border-radius: 50%;
    top: -10px;
    width: 12px;
    height: 12px;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
    color: $c-bwl-100-d-900;
    font-size: 7px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 0;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      animation-fill-mode: both;
      animation: bblFadInOut 1.8s infinite ease-in-out;
    }

    &:before {
      left: -24px;
      animation-delay: -0.32s;
    }

    &:after {
      left: 24px;
    }

    @keyframes bblFadInOut {
      0%, 80%, 100% {
        box-shadow: 0 12px 0 -8px
      }
      40% {
        box-shadow: 0 12px 0 0
      }
    }

  }


  //&:disabled {
  //  pointer-events: none;
  //  opacity: .6;
  //}
  //&[disabled] {
  //  border: 1px solid var(--c-btn-disabled-bg);
  //  background: var(--c-btn-disabled-bg);
  //  color: var(--c-btn-disabled-text);
  //  pointer-events: none;
  //
  //  &:after {
  //    display: none;
  //  }
  //}

  //---size
  &--big {
    min-height: 56px;
    padding: 13px 20px;
    border-radius: 6px;
  }

  &--small {
    min-height: 32px;
    padding: 4px 16px;
    border-radius: 4px;
  }

  //---type
  &--fill {
    color: $c-bwl-100-d-900;
    background: $c-ua-hg-primary-100;
    border: 1px solid $c-ua-hg-primary-100;

    &:hover {
      background: $c-ua-hg-primary-125;
      border: 1px solid $c-ua-hg-primary-125;
    }

    &:active {
      background: $c-ua-hg-primary-150;
      border: 1px solid $c-ua-hg-primary-150;
    }

    &[disabled] {
      border: 1px solid $c-bwl-400-d-600;
      background: $c-bwl-400-d-600;
      pointer-events: none;
    }
  }

  &--fill-3 {
    color: $c-bwl-100-d-900;
    background: $c-ua-hg-primary-125;
    border: 1px solid $c-ua-hg-primary-125;

    &:hover {
      background: $c-link;
      border: 1px solid $c-link;
    }

    &:active {
      background: $c-ua-hg-primary-150;
      border: 1px solid $c-ua-hg-primary-150;
    }

    &[disabled] {
      border: 1px solid $c-bwl-400-d-600;
      background: $c-bwl-400-d-600;
      pointer-events: none;
    }
  }

  &--outline {
    color: $c-bwl-700-d-300;
    background: transparent;
    border: 1px solid $c-ua-hg-primary-100;

    &:hover {
      background: transparent;
      border: 1px solid $c-ua-hg-primary-125;
    }

    &:active {
      background: transparent;
      border: 1px solid $c-ua-hg-primary-150;
    }

    &[disabled] {
      color: $c-bwl-400-d-600;
      border: 1px solid $c-bwl-400-d-600;
      pointer-events: none;
    }
  }

  &--flat {
    color: $c-bwl-700-d-300;
    background: transparent;
    border: 1px solid transparent;

    &:hover {
      background: $c-bwl-300-d-700;
      border: 1px solid $c-bwl-300-d-700;
    }

    &:active {
      color: $c-bwl-100-d-900;
      background: $c-ua-hg-primary-150;
      border: 1px solid $c-ua-hg-primary-150;
    }

    &[disabled] {
      color: $c-bwl-400-d-600;
      background: transparent;
      border: 1px solid transparent;
      pointer-events: none;
    }
  }
}

.app-button-append {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.app-button-prepend {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

//a.app-button {
//  .app-button__content {
//    margin-top: 3px;
//  }
//}

@media screen and (max-width: 1024px) {
  .app-button {
    &__loader {
      top: -6px;
      width: 8px;
      height: 8px;
      animation: bblFadInOut 1.8s infinite ease-in-out;
      animation-delay: -0.16s;

      &:before, &:after {
        width: 8px;
        height: 8px;
        animation: bblFadInOut 1.8s infinite ease-in-out;
      }

      &:before {
        left: -20px;
        animation-delay: -0.32s;
      }

      &:after {
        left: 20px;
      }

      @keyframes bblFadInOut {
        0%, 80%, 100% {
          box-shadow: 0 8px 0 -4px
        }
        40% {
          box-shadow: 0 8px 0 0
        }
      }
    }
  }
}
