@import "src/scss/vars";
@import "src/scss/mixin";

.page404 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 16px;
  }
  &__title {
    color: $c-turquoise-125;
    font-size: 72px;
    font-weight: 700;
    line-height: 140%;
  }
  &__text {
    margin-top: 32px;
    color: $c-bwl-700-d-300;
    font-size: 48px;
    line-height: 150%;
  }
  &__btn {
    margin-top: 32px;
  }
}

@media screen and (max-width: 1024px) {
  .page404 {
    padding-top: 115px;
    &__title {
      font-size: 58px;
    }
    &__text {
      font-size: 38px;
    }
  }
}


@media screen and (max-width: 768px) {
  .page404 {
    &__title {
      font-size: 46px;
    }
    &__text {
      font-size: 30px;
    }
  }
}
