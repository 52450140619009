@import "src/scss/mixin";
@import "src/scss/vars";

.home-news {
  padding-top: 112px;

  &__title {
    font-weight: 700;
    text-align: center;
    @include fs-lh(36px, 48px);
    letter-spacing: 0.02em;
    color: $c-blue--dark;
  }


  &__content {
    position: relative;
    margin-top: 32px;
    overflow: hidden;

    .swiper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 2px 2px 0;
    }


    .swiper-wrapper {
      order: 1;
      width: 100%;
      align-items: stretch;
      padding-bottom: 4px;
      margin-bottom: 40px;
    }

    .swiper-container {
      width: 100%;
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-pagination {
      order: 3;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      display: inline-flex;
      align-items: center;
      margin: 0 36px;
      padding: 12px 8px;
      background-color: $c-bwl-100-d-900;
      width: auto !important;
      border-radius: 16px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 4px !important;
      border-radius: 50%;
      background: $c-bwl-300-d-700;
      opacity: 1;

      &:hover, &:active {
        background: $c-ua-hg-primary-125;
      }

      &.swiper-pagination-bullet-active {
        background: $c-ua-hg-primary-125;
      }
    }

    .swiper-button-prev, .swiper-button-next {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      margin-top: auto;
      color: $c-bwl-500-d-500;
      background-color: $c-bwl-100-d-900;
      border-radius: 50%;

      &:after {
        position: relative;
        font-size: 14px;
      }

      &:hover, &:active {
        color: $c-ua-hg-primary-125;

      }
    }

    .swiper-button-prev {
      order: 2;
    }

    .swiper-button-next {
      order: 4;
    }

  }

  &__card {
    display: block;
    height: 100%;
    padding: 24px;
    border-radius: 8px;
    text-decoration: none;
    background-color: rgba($c-bwl-100-d-900, .75);

    box-shadow: 0 1px 3px 0 #0000001C, 0 2px 2px 0 #0000000A, 0 0 2px 0 #0000000F;

    &:hover, &:active {
      img {
        transform: scale(1.2);
      }
    }
  }

  &__card-img {
    overflow: hidden;
    border-radius: 6px;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1.9;
      transform: scale(1);
      will-change: transform;
      @include tr-cb();
    }
  }

  &__card-title {
    min-height: 56px;
    margin-top: 24px;
    font-weight: 700;
    @include fs-lh(18px, 28px);
    letter-spacing: 0.01em;
    @include text-dots(2);
    color: $c-bwl-900-d-100;
  }

  &__card-text {
    margin-top: 8px;
    font-weight: 400;
    @include fs-lh(16px, 28px);
    @include text-dots(2);
    color: $c-black;
  }
}

@media screen and (max-width: 1024px) {
  .home-news {

    &__title {
      font-size: 24px;
    }
  }

}

@media screen and (max-width: 768px) {
  .home-news {
    &__content {
      margin-right: -16px;

      .swiper-slide {
        width: 282px;

      }
    }
  }
}

@media screen and (max-width: 480px) {
  .home-news {
    &__title {
      text-align: left;
    }
  }
}