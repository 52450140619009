@import "../../../scss/mixin";
@import "../../../scss/vars";


.weld-cashback {
  padding-top: 85px;
  text-align: center;

  &__blue-txt {
    font-size: 14px;
    color: $c-ua-hg-primary-75;
  }

  &__title {
    color: $c-blue--dark;
    font-weight: 700;
    @include fs-lh(36px,48px);
    letter-spacing: 0.02em;
    span {
      white-space: nowrap;
    }
  }

  &__desc {
    max-width: 658px;
    @include margin-center();
    margin-top: 16px;
    @include fs-lh(16px,28px)
  }
  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 16px;
    margin-top: 32px;
  }
  &__card {
    width: calc(50% - 8px);
    border-radius: 16px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  &__banner {
    margin: 16px 0;
    img {
      width: 100%;
      border-radius: 16px;
    }

  }

  &__wrapTitle {
    display: flex;
    align-items: center;
    h5 {
      margin-left: 15px;
    }
    img {
      width: 24px;
    }
  }
  &__cashback-info {
    width: 100%;
    min-height: 144px;
    padding: 16px 32px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #FFF2C1;

    h5 {
      width: fit-content;
      font-size: 16px;
      font-weight: 700;
      color: $c-blue--dark;
    }
    &__tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      span {
        margin: 6px;
        padding: 8px 24px;
        background-color: #fff;
        font-size: 16px;
        color: $c-blue--dark;
        border-radius: 4px;

      }
    }
  }
  &__note {
    margin-top: 8px;
    color: $c-bwl-600-d-400;
    @include fs-lh(10px,12px)
  }

  &__referal-box {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    gap: 32px;
    flex-wrap: wrap;
    padding: 32px 0;

    &__note {
      font-size: 20px;
      color: $c-bwl-500-d-500;
    }
    &__card {
      width: 276px;
      min-height: 532px;
      height: 100%;
      padding: 35px 24px 24px;
      border-radius: 16px;
      color: $c-blue--dark;
      h5 {
        align-content: center;
        padding-top: 25px;
        font-size: 32px;
        font-weight: 700;
        height: 85px;
      }

      span {
        display: block;
        font-size: 14px;
        padding-bottom: 4px;
      }
      p {
        font-size: 20px;
        font-weight: 700;
      }
    }
    .cardBlue100 {
      background-color: #F4F9FF;
    }
    .cardBlue150 {
      background-color: #EEF6FF;
    }
    .cardBlue200 {
      background-color: #DFEEFF;
    }
    .cardBlue250 {
      background-color: #D2E7FF;
    }
    &__line {
      height: 2px;
      width: 228px;
      margin: 24px 0;
      background-color: $c-link;
    }
    &__wrap-info {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      min-height: 60px;

    }
    &__row-star {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .weld-cashback {

    &__title {
      @include fs-lh(24px,40px);
    }

    &__desc {
      margin-top: 24px;
    }
    &__grid {
      margin-top: 24px;
    }
    &__card {
      border-radius: 9px;
    }
    &__note {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .weld-cashback {
    &__grid {
      flex-direction: column;
    }
    &__card {
      width: 100%;
    }
  }
}

@media screen and (max-width: 616px) {
  .weld-cashback {
    &__referal-box {
      &__card {
        min-height: initial;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .weld-cashback {
    &__wrapTitle {
      h5 {
        margin-left: 0;
      }
    }
    &__cashback-info {
      &__tags {
        span {
          width: 100%;
          margin: 8px 0;
        }
      }
    }
  }
}