@import "src/scss/mixin";
@import "src/scss/vars";

.home-cta {
  padding-top: 132px;

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
  }

  &__content {
    width: 100%;
    max-width: 485px;
    margin-top: 18px;

  }

  &__title {
    font-weight: 700;
    @include fs-lh(48px, 57.6px);
    letter-spacing: 0.02em;
    color: $c-ua-hg-primary-125;
  }

  &__text {
    margin-top: 32px;
    font-weight: 400;
    @include fs-lh(16px, 28px);
    text-align: left;
  }

  &__actions {
    margin-top: 28px;
  }

  &__btn {
    width: 330px;
  }

  &__img {
    position: relative;
    margin-right: 7.7%;

    svg {
      position: absolute;
      top: -20%;
      left: -12%;
      width: 123.2%;
      height: auto;
    }

    img {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      max-width: 531px;
    }
  }

}

@media screen and (max-width: 1024px) {
  .home-cta {
    padding-top: 80px;

    &__content {
      max-width: 330px;
    }

    &__title {
      @include fs-lh(24px,28.8px);
    }

    &__text {
      margin-top: 16px;
    }

    &__img {
      img {
        max-width: 425px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home-cta {
    &__grid {
      flex-direction: column;
      gap: 16px;
    }

    &__content {
      max-width: 485px;
      margin-top: 0;
      text-align: center;
    }

    &__img {
      margin-top: 60px;
      margin-right: 0;

      img {
        max-width: 280px;
      }
    }

    &__actions {
      margin-top: 71px;
    }
  }
}
