@import "src/scss/mixin";
@import "src/scss/vars";

.reserves-audit {
  position: relative;
  padding-top: 60px;
  padding-bottom: 120px;
  color: $c-bwl-700-d-300;
  background: no-repeat 
  url("../../../assets/images/reserves-audit-bg.png");
  background-position: 11% 15%;
  background-size: 655px 600px;
  

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 620px;
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%; /* 57.6px */
    letter-spacing: 0.96px;
  }

  &__text {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%; /* 175% */
  }

  &__actions {
    margin-top: 32px;
  }

  &__btn {
    min-width: 210px;
  }

  &__slider {
    max-width: 480px;

    .swiper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-self: flex-start;
      width: 100%;
    }

    .swiper-wrapper {
      order: 1;

    }

    .swiper-pagination {
      order: 3;
      position: relative;
      top: auto;
      bottom: auto;
      width: auto;
      left: auto;
      margin: 34px 12px 0;
    }

    .swiper-slide {
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;

    }

    .swiper-button-prev, .swiper-button-next {
      position: relative;
      top: auto;
      bottom: auto;
      width: 40px;
      height: 40px;
      margin-top: 24px;
      color: #939393;
      cursor: pointer;

      &:after {
        width: 40px;
        height: 40px;
        font-size: 24px;
        text-align: center;
        line-height: 40px;
        color: #939393;
      }

    }

    .swiper-button-prev {
      order: 2;

    }

    .swiper-button-next {
      order: 4;

    }
  }

  &__card {
    display: block;
    margin: 6px;
    box-shadow: 0px 0.169px 0.508px 0px rgba(0, 0, 0, 0.04), 0px 0.469px 1.406px 0px rgba(0, 0, 0, 0.10), 0px 0.936px 5.613px 0px rgba(0, 0, 0, 0.06), 0px 3.742px 11.227px 0px rgba(0, 0, 0, 0.09);

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

}

.pswp__counter {
  display: none;
}
.pswp__button--arrow{
  display: none;
}

.reserves-audit__photoswipe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    object-fit: cover;
    max-height: 95vh;
    max-width: 95vw;
    height: auto;
  }
}

@media screen and (max-width: 1280px) {
  .reserves-audit {
    padding-bottom: 60px;

    &__grid {
      gap: 36px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .reserves-audit {
    background-position: 50% 70%;

    &__actions a {
      width: 100%;
    }

    &__grid {
      flex-direction: column-reverse;
    }

    &__title {
      font-size: 36px;
    }

    &__text {
      margin-top: 16px;
    }

    &__slider {
      max-width: 343px;
    }
  }
}
