@import "src/scss/mixin";
@import "src/scss/vars";

.weld-banner {
  &__grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    max-width: 583px;
  }

  &__subtitle {

    max-width: 495px;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.02em;
    color: $c-ua-hg-primary-125;

    &--1 {
      margin-top: 16px;
    }
  }

  &__actions {
    margin-top: 24px;
  }

  &__btn {
    width: 225px;
  }

  &__text {
    max-width: 360px;
    margin-top: 24px;
    @include fs-lh(12px, 16px);
    font-weight: 400;
  }

  &__note {
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: $c-bwl-600-d-400;
    a {
      color: $c-ua-hg-primary-125;
      @include trCubic();
      text-decoration: underline;
      &:hover,&:active {
        text-decoration: none;
      }
    }
  }


  &__imgs {
    position: relative;
    margin-top: 32px;
    margin-right: 130px;
  }

  &__img {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    max-width: 445px;
    min-width: 430px;
  }

  &__subimg-1 {
    position: absolute;
    top: -77px;
    right: -155px;
    z-index: 2;
    width: 269px;
  }

  &__subimg-2 {
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: -178px;
    width: 269px;
  }

  &__arrow-down {
    position: absolute;
    z-index: 2;
    top: -33px;
    right: 78px;
  }

  &__arrow-up {
    position: absolute;
    z-index: 2;
    bottom: 60px;
    left: -5px;
  }

}

@media screen and (max-width: 1140px) {
  .weld-banner__imgs {
    margin-right: 100px;
    margin-left: 100px;

  }
}

@media screen and (max-width: 1024px) {
  .weld-banner {
    padding-bottom: 0;

    &__subimg-1 {
      top: -74px;
      right: -154px;
    }

    &__grid {
      flex-direction: column;
      align-items: center;
    }

    &__content {
      text-align: center;
      max-width: 485px;
    }

    &__note {
      max-width: 485px;
    }

    &__subtitle {
      max-width: 495px;
      font-size: 16px;
      line-height: 28px;

      &--1 {
        margin-top: 16px;
      }
    }

    &__actions {
      margin-top: 16px;
    }

    &__text {
      margin-top: 16px;
    }

    &__imgs {
      position: relative;
      margin-top: 48px;
      margin-right: 0;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .weld-banner {

    &__img {
      max-width: 225px;
      min-width: auto;
    }

    &__subimg-1 {
      width: 117px;
      right: -64px;
      top: -26px;
    }

    &__subimg-2 {
      width: 137px;
      bottom: 13px;
      left: -91px;
    }

    &__arrow-down {
      top: -18px;
      left: 110px;
      width: 70px;
      height: auto;
    }

    &__arrow-up {
      bottom: 30px;
      left: -15px;
      width: 70px;
      height: auto;
    }

    &__note {
      bottom: 16px;
      width: 150px;
      margin-left: -75px;
      @include fs-lh(5px,6px)
    }
  }
}
