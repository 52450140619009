@import "../../../scss/mixin";
@import "../../../scss/vars";

.market-services {
  padding-top: 112px;

  &__box {
    position: relative;
    z-index: 1;
    padding: 32px 50px 26px;
    border-radius: 8px;
    background-color: $c-blue--dark;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 42px;
    width: 100%;
  }

  &__card {
    color: $c-bwl-100-d-900;
    transform: scale(1);
    @include tr-cb();

    &:hover, &:active {
      transform: scale(1.1);
    }

    img {
      display: block;
      max-height: 36px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px) {
  .market-services {
    &__box {
      padding: 23px 15px 17px;
      min-height: 72px;
    }

    &__list {
      padding: 0 21px;
    }

    &__card {
      img {
        max-height: 32px;
      }
    }
  }
}