@import "src/scss/vars";
@import "src/scss/mixin";

.weld {
  position: relative;
  padding-top: 200px;
  background-color: #F7F7F7;

  &__oveflow {
    overflow: hidden;
  }

  &__usage-slides {
    padding-top: 80px;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: $c-blue--dark;

    &--blue {
      color: $c-ua-hg-primary-125;
    }
  }

  &__btn {
    width: 225px;
    min-height: 46px;
    font-weight: 700;
  }

}

@media screen and (max-width: 1024px) {
  .weld {
    padding-top: 104px;

    &__title {
      font-size: 24px;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .weld {
    &__usage-slides {
      padding-top: 0;
    }
  }
}
