@import "src/scss/vars";
@import "src/scss/mixin";

.team {
  position: relative;
  padding-top: 200px;
  padding-bottom: 96px;

  &__head {

  }

  &__title {
    text-align: center;
    color: $c-blue--dark;
    font-size: 64px;
    font-weight: 700;
    line-height: 125%; /* 125% */
    letter-spacing: 1.92px;
  }

  &__text {
    text-align: center;
    margin-top: 16px;
    color: $c-ua-hg-primary-125;
    font-size: 24px;
    font-weight: 400;
    line-height: 166.667%; /* 166.667% */
    letter-spacing: 0.48px;
  }

  &__grid {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 48px;
    gap: 36px;
  }

  &__card {
    width: calc(33.33% - 24px);
    text-align: center;
  }
  &__card-inner {
    max-width: 315px;
    margin: 0 auto;
  }
  &__card-img {
    max-width: 192px;
    margin: 0 auto;
    img {
      display: block;
      width: 100%;

      border-radius: 50%;
      object-fit: cover;
      aspect-ratio: 1;
    }
  }

  &__card-title {
    margin-top: 16px;
    color: $c-ua-hg-primary-125;
    font-size: 24px;
    font-weight: 700;
    line-height: 166.667%; /* 166.667% */
    letter-spacing: 0.48px;
  }

  &__card-note {
    margin-top: 8px;
    color: $c-bwl-400-d-600;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }

  &__card-desc {
    margin-top: 8px;
    color: $c-bwl-700-d-300;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; /* 175% */
  }
}

@media screen and (max-width: 1024px) {
  .team {
    padding-top: 152px;
    padding-bottom: 60px;

    &__title {
      font-size: 36px;
    }
    &__card {
      width: calc(50% - 18px);
    }
  }
}

@media screen and (max-width: 768px) {
  .team {
    &__grid {
      flex-direction: column;
      align-content: center;
    }
    &__card-inner {
      max-width: 343px;
    }
  }
}
