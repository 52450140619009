@import "src/scss/vars";
@import "src/scss/mixin";

.coming-soon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 64px;
  flex-grow: 1;
  overflow: hidden;
  .app__wrapper {
    width: 100%;
  }
  &__box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 520px;
    width: 100%;
    min-height: 292px;
    padding: 16px;
    @include margin-center();
    background-color: $c-box;
    border-radius: 16px;
  }
  &__box-icon-1 {
    position: absolute;
    top: 0;
    left: -55px;
    display: block;
  }
  &__box-icon-2 {
    position: absolute;
    bottom: 0;
    right: -65px;
    display: block;
  }
  &__title {
    color: $c-ua-hg-primary-125;
    font-weight: 700;
    @include fs-lh--adp(48px,48.86px);
  }
}

@media screen and (max-width: 768px) {
  .coming-soon {
    &__box {
      min-height: 272px;
    }
    &__box-icon-1 {
      top: -4;
      left: -28px;
      width: 115px;
    }
    &__box-icon-2 {
      bottom: -4px;
      right: -28px;
      width: 125px;
    }
    &__title {
      font-size: 40px;
    }
  }
}