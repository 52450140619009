@import "src/scss/mixin";
@import "src/scss/vars";

.weld-examples {
  position: relative;
  padding-top: 52px;

  .weld__title {
    max-width: 675px;
    margin: 0 auto;
  }

  &__head {
    text-align: center;
  }

  &__text {
    max-width: 900px;
    margin: 32px auto 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 32px;
    max-width: 1120px;
    margin: 32px auto 0;
  }

  &__card {
    position: relative;
    width: calc(33.33% - 22px);
    padding: 24px 24px 40px;
    border: 1px solid #E7E7E7;
    border-radius: 8px;
    box-shadow: 0px 3px 6.2px 0px #6D63DB14;
    overflow: hidden;
    background-color: $c-bwl-100-d-900;
  }

  &__card-imgs {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    padding: 12px;
    background-color: $c-ua-hg-primary-125;
    border-radius: 12px;

    img {
      display: block;
      width: 24px;
      aspect-ratio: 1;
    }

  }

  &__card-title {
    position: relative;
    z-index: 1;
    margin-top: 14px;
    padding-right: 70px;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    color: $c-turquoise-125;
  }

  &__card-num {
    position: absolute;
    right: 16px;
    bottom: -40px;
    font-size: 96px;
    font-weight: 400;
    line-height: 124px;
    letter-spacing: 0.02em;
    color: #F3F4F6;
  }

  &__actions {
    margin-top: 32px;
    text-align: center;

  }

  &__btn {
    width: 240px;
    @include margin-center();
  }
}

@media screen and (max-width: 1024px) {
  .weld-examples {
    padding-bottom: 0;

    .weld__title {
      max-width: 485px;
    }

    &__text {
      max-width: 900px;
      margin: 8px auto 0;
    }

    &__grid {
      gap: 24px;
      margin: 24px auto 0;
    }

    &__card {
      width: calc(50% - 12px);
    }
  }
}

@media screen and (max-width: 768px) {
  .weld-examples {
    &__card {
      width: calc(50% - 12px);
    }
  }
}

@media screen and (max-width: 768px) {
  .weld-examples {
    &__card {
      width: 100%;
    }
  }
}
