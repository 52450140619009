@import "src/scss/mixin";
@import "src/scss/vars";

.proof-card {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  border-radius: 6px;
  padding: 8px 24px;
  background: $c-bwl-100-d-900;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.01), 0px 1px 3px 0px rgba(0, 0, 0, 0.03), 0px 4px 12px 0px rgba(0, 0, 0, 0.04);

  &__content {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex-grow: 1;

    img {
      display: block;
      width: 24px;
      aspect-ratio: 1;
      object-fit: cover;
    }

    span {
      color: $c-bwl-700-d-300;
      font-size: 16px;
      font-weight: 700;
      line-height: 28px; /* 175% */
    }
  }

  &__link {
    padding: 10px 8px;
    border-radius: 4px;
    border: 1px dashed $c-bwl-300-d-700;
    background: $c-bwl-100-d-900;
    min-width: 395px;

    a {
      color: $c-ua-hg-primary-125;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 175% */
      text-decoration-line: underline;
      @include trCubic();
      &:hover, &:active {
        text-decoration: none;
      }
    }
  }
  &__btn {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 768px) {
  .proof-card {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 8px;
    &__link {
      width: 100%;
      min-width: auto;
      word-break: break-all;
    }
  }
}
